export const URL = {
	withoutAuth: "/",
	withAuth: "/deals",

	apiLocal: "http://platform.local/api",
  apiGlobal: "https://platform-v1.digitalincloud.ru/api",

	getApi: function()
	{
		return (window.location.hostname == "localhost" ? this.apiLocal : this.apiGlobal);
	}
};
  