import { URL } from './settings';
import { NotificationManager } from 'react-notifications';

//##########################################################################

export default function ajax({url, type="get", data=null, handler=null})
{
	if(type.toLowerCase() == "get" && data != null)
		url += "?"+data;
  
  let oQuery = new XMLHttpRequest();
  oQuery.open(type, URL.getApi()+url, true);
	oQuery.timeout = 10000;
	oQuery.ontimeout = () => { alert("Timeout!"); };
    
	oQuery.send((type.toLowerCase() == "get" ? null : JSON.stringify(data)));

  oQuery.onreadystatechange=function(){
    if(oQuery.readyState != 4) 
      return;

		let json = JSON.parse(oQuery.responseText);

		if(!json.success)
		{
			if(json.action == "login")
			{
				window.location.replace("/");
				localStorage.clear();
				return;
			}
			NotificationManager.error(json.error);
		}
        
		if(oQuery.status == 200)
    	handler(json);
		else
			alert("Server error!\r\nResponse code: "+oQuery.statusText+"\r\nResponse text: "+oQuery.responseText);
  }
}
