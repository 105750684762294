import React from 'react';
import {Redirect} from 'react-router-dom';

import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from 'react-notifications';

import {Button} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import { URL } from '../settings';

import ajax from '../ajax';

import 'bootstrap/dist/css/bootstrap.min.css';

//##########################################################################

class Login extends React.Component
{
	constructor()
	{
		super();
		this.state = {
			email: "", 
			password: "", 
			redirect: false
		};
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}

	render() 
	{
		if(
			this.state.redirect ||
			(localStorage.hasOwnProperty("token") && localStorage.hasOwnProperty("expire") &&
			localStorage.expire > Date.now()/1000)
		)
			return <Redirect to={URL.withAuth} />

		return (
			<div>
				<Modal show={true} onHide={()=>{}} centered backdrop="static" keyboard={false}>
					<Form onSubmit={this.handleFormSubmit} className="border">
						<Modal.Header>
							<Modal.Title>Авторизация</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form.Group controlId="formEmail">
								<Form.Label>Email:</Form.Label>
								<Form.Control type="email" placeholder="Введите email" value={this.state.email} onChange={e => this.handleChangeValue(e, 'email')} required/>
							</Form.Group>
							<Form.Group controlId="formBasicPassword" style={{marginTop: "10px"}}>
								<Form.Label>Пароль:</Form.Label>
								<Form.Control type="password" placeholder="Введите пароль" value={this.state.password} onChange={e => this.handleChangeValue(e, 'password')} required/>
							</Form.Group>
							<Button style={{marginTop: "10px", width: "100%"}} variant="primary" type="submit" size="lg" block>Войти</Button>
						</Modal.Body>
					</Form>
				</Modal>
				<NotificationContainer/>
			</div>
		)
	}

	//########################################################################

	handleChangeValue(e, type)
	{
		const value = e.target.value;
		const nextState = {};
		nextState[type] = value;
		this.setState(nextState);
	}

	//************************************************************************

	handleFormSubmit(e)
	{
		e.preventDefault();

		/*let aPost = {login: this.state.email, password: this.state.password};
		console.log(aPost);*/
		
		let o = this;
		ajax({
			url: "/customer/auth",
			type: "post",
			data: {login: this.state.email, password: this.state.password},
			handler: (json) => {
				localStorage.setItem('token', json.data.token);
				localStorage.setItem('expire', json.data.expire);
				localStorage.setItem('login', o.state.email);
				o.setState({redirect: URL.withAuth});
			}
		});
	}
}

export default Login;
