import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';
import pangaea from '../pangaea';
import QRCode from 'qrcode.react';

//##########################################################################

class Check extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			check: this.props.check,
			tin: this.props.tin
		};
	}

	getVatPercent(iVatNum)
	{
		let iVat = 0;
		switch(iVatNum)
		{
			case 1:
				iVat = 20;
				break;
			case 2:
				iVat = 10;
				break;
			default:
				break;
		}

		return iVat;
	}

	render() 
	{
		//console.log(this.state);

		//вычисляем сумму без НДС
		let fWithoutVat = 0;

		this.state.check.content.t1059.map((product, i) =>
		{
			let iSum = product.t1079*product.t1023;
			if(product.hasOwnProperty("t1199"))
            {
                let fVat = pangaea.t1199_toPercet(product.t1199)
				fWithoutVat += iSum - (iSum * fVat / (100+fVat));
            }
			else
				fWithoutVat += iSum;

			return 0;
		});
		fWithoutVat /= 100;
		//************************************************

		//строки с товарами
		const products = (
			this.state.check.content.t1059.length ? 
				this.state.check.content.t1059.map((product, i) =>
					<tr key={i}>
						<td>{i+1}</td>
						<td>
							{product.t1030}<br/>
							<small>{pangaea.t1212_toText(product.t1212)} / {pangaea.t1214_toText(product.t1214)}</small>
                            {product.hasOwnProperty("t1224") ?
                                <small><br/>Поставщик:<br/>Наименование: {product.t1224.t1225}<br/>Телефон:{product.t1224.t1171}<br/>ИНН:{product.t1226}</small>
                            : ""
                            }
						</td>
						<td style={{textAlign: "right"}}>{product.t1079/100}₽ x {product.t1023} = {product.t1079*product.t1023/100}₽</td>
					</tr>
				) 
			: <tr></tr>
		);

		//данные для QR
		let sQR = 't=' + this.state.check.response_data.reg_time +
				'&s=' + (this.state.check.content.t1081 / 100) +
				'&fn=' + this.state.check.response_data.fn +
				'&i=' + this.state.check.response_data.document_num +
				'&fp=' + this.state.check.response_data.fp +
				'&n=1';
		//console.log(sQR);

		//формируем время
		let sTime = this.state.check.response_data.reg_time;
		sTime = sTime.substr(0,4)+"-"+sTime.substr(4,2)+"-"+sTime.substr(6,2)+" "+sTime.substr(9,2)+":"+sTime.substr(11,2);

		return (
			<div>
				<Table size="sm" hover>
					<tbody>
						<tr>
							<td style={{textAlign: "center"}} colSpan="2"><h3>{this.state.check.content.t1187}</h3></td>
						</tr>
						<tr>
							<td>Дата</td>
							<td style={{textAlign: "right"}}>{sTime}</td>
						</tr>
						<tr>
							<td>ИНН</td>
							<td style={{textAlign: "right"}}>{this.state.tin}</td>
						</tr>
						<tr>
							<td>Налогообложение</td>
							<td style={{textAlign: "right"}}>{(this.state.check.hasOwnProperty("t1055") ? pangaea.taxation_toText(this.state.check.t1055) : "--")}</td>
						</tr>
					</tbody>
				</Table>

				<Table size="sm" striped bordered hover>
					<thead>
						<tr>
							<th>№</th>
							<th>Наименование</th>
							<th style={{textAlign: "right"}}>Сумма</th>
						</tr>
					</thead>
					<tbody>
						{products}
					</tbody>
				</Table>

				<Table size="sm" hover>
					<tbody>
					<tr>
						<td><b>Итого</b></td>
						<td style={{textAlign: "right"}}>
							{
								((this.state.check.content.hasOwnProperty("t1031") ? this.state.check.content.t1031/100 : 0) + 
								(this.state.check.content.hasOwnProperty("t1081") ? this.state.check.content.t1081/100 : 0) + 
								(this.state.check.content.hasOwnProperty("t1215") ? this.state.check.content.t1215/100 : 0) + 
								(this.state.check.content.hasOwnProperty("t1216") ? this.state.check.content.t1216/100 : 0) + 
								(this.state.check.content.hasOwnProperty("t1217") ? this.state.check.content.t1217/100 : 0)).toFixed(2)
							}₽
						</td>
					</tr>
					<tr>
						<td>Безналичными</td>
						<td style={{textAlign: "right"}}>{this.state.check.content.hasOwnProperty("t1081") ? (this.state.check.content.t1081/100).toFixed(2) : 0}₽</td>
					</tr>
                    <tr>
						<td>Предоплатой</td>
						<td style={{textAlign: "right"}}>{this.state.check.content.hasOwnProperty("t1215") ? (this.state.check.content.t1215/100).toFixed(2) : 0}₽</td>
					</tr>
                    <tr>
						<td>Встречным представлением</td>
						<td style={{textAlign: "right"}}>{this.state.check.content.hasOwnProperty("t1217") ? (this.state.check.content.t1217/100).toFixed(2) : 0}₽</td>
					</tr>
					<tr>
						<td>Сумма без НДС</td>
						<td style={{textAlign: "right"}}>{fWithoutVat.toFixed(2)}₽</td>
					</tr>
					</tbody>
				</Table>

				<Table size="sm" hover>
					<tbody>
						<tr>
							<td>Номер смены</td>
							<td style={{textAlign: "right"}}>{this.state.check.response_data.shift_num}</td>
						</tr>
						<tr>
							<td>Номер ФД</td>
							<td style={{textAlign: "right"}}>{this.state.check.response_data.document_num}</td>
						</tr>
						<tr>
							<td>ФПД</td>
							<td style={{textAlign: "right"}}>{this.state.check.response_data.fp}</td>
						</tr>
						<tr>
							<td>Номер ФД в смене</td>
							<td style={{textAlign: "right"}}>{this.state.check.response_data.index}</td>
						</tr>
					</tbody>
				</Table>

				<Table size="sm" hover>
					<tbody>
						<tr>
							<td>Рег. номер ККТ в ФНС</td>
							<td style={{textAlign: "right"}}>{this.state.check.response_data.cashier_rn}</td>
						</tr>
						<tr>
							<td>Сер. номер ККТ</td>
							<td style={{textAlign: "right"}}>{this.state.check.response_data.cashier_factory_num}</td>
						</tr>
						<tr>
							<td>Сер. номер ФН</td>
							<td style={{textAlign: "right"}}>{this.state.check.response_data.fn}</td>
						</tr>
						<tr>
							<td>Версия ФФД</td>
							<td style={{textAlign: "right"}}>{this.state.check.response_data.cashier_ffd}</td>
						</tr>
					</tbody>
				</Table>

				<Table size="sm" hover>
					<tbody>
						<tr>
							<td>Эл. адрес покупателя</td>
							<td style={{textAlign: "right"}}><a href={"mailto:"+this.state.check.content.t1008}>{this.state.check.content.t1008}</a></td>
						</tr>
						<tr>
							<td>Эл. адрес отправителя</td>
							<td style={{textAlign: "right"}}><a href="mailto:no-reply@digitalkassa.ru">no-reply@digitalkassa</a></td>
						</tr>
						<tr>
							<td>Адрес сайта ФНС</td>
							<td style={{textAlign: "right"}}><a href="https://www.nalog.ru" target="_blank" rel="noreferrer">https://www.nalog.ru</a></td>
						</tr>
						<tr>
							<td>Название ОФД</td>
							<td style={{textAlign: "right"}}>Яндекс.ОФД</td>
						</tr>
						<tr>
							<td>Сайт ОФД для проверки чека</td>
							<td style={{textAlign: "right"}}><a href="https://ofd.yandex.ru/check" target="_blank" rel="noreferrer">https://ofd.yandex.ru/check</a></td>
						</tr>
						<tr>
							<td>ИНН ОФД</td>
							<td style={{textAlign: "right"}}>7704358518</td>
						</tr>
					</tbody>
				</Table>

				<div 
					style={{
						width: "100%", 
						display: "flex", 
						alignItems: "center", 
						alignContent: "center", 
						justifyContent: "center", 
						margin: "30px auto", 
						textAlign: "center", 
						position: "relative"
					}}
				>
					<QRCode value={sQR} />
				</div>
			</div>
		)
	}
}

export default Check;
