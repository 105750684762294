import React from 'react';

import Table from 'react-bootstrap/Table';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Navbar from 'react-bootstrap/Navbar';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//https://reactdatepicker.com/
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import PageNavi from '../components/PageNavi';
import OrderProducts from '../components/OrderProducts';
import Check from '../components/check';
import Refund from '../components/refund';

import ajax from '../ajax';

//##########################################################################

class Deals extends React.Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			data: [], 
			related: {},
            aggregate: {},
			pagination: {
				curr: 1,
				per: 10,
				count: 0
			},
            filter: {
                enable: false,
                dateFrom: 0,
                dateTo: 0,
                email: "",
                FIO: "",
            }
		};
		this.handleTrClick = this.handleTrClick.bind(this);
		this.loadData = this.loadData.bind(this);

        this.handleRefundAction = this.handleRefundAction.bind(this);

        this.handleChangeFilterDateFrom = this.handleChangeFilterDateFrom.bind(this);
        this.handleChangeFilterDateTo = this.handleChangeFilterDateTo.bind(this);
        this.handleChangeFilterEmail = this.handleChangeFilterEmail.bind(this);
        this.handleChangeFilterFIO = this.handleChangeFilterFIO.bind(this);

        this.handleClickFilterEnable = this.handleClickFilterEnable.bind(this);

		this.loadData();
	}

	render() 
	{
		let oStyleDiscount = {
			backgroundColor: "rgba(0, 255, 0, 0.2)", 
			borderBottom: "4px solid rgba(0, 255, 0, 0.5)"
		};
		const trs = (
			this.state.data.length ? 
				this.state.data.map((obj, i) =>
					<tr id={i} onClick={this.handleTrClick} key={i} style={(obj.discount > 0 ? oStyleDiscount : {})}>
						<td>{obj.order} /<br/> <small>{obj.date_add}</small></td>
						<td>
							<a target="_blank" rel="noreferrer" href={this.state.related.project[obj.project].site}>{this.state.related.project[obj.project].site.replace(/^https?:\/\//, "").replace(/\/$/, "")}</a>
						</td>
						<td>{obj.name} / <a href={"mailto:"+obj.email}>{obj.email}</a></td>
						<td>{obj.amount}₽ / {(obj.payed ? "Оплачено" : "Не оплачено")}</td>
						<td style={{textAlign: "center"}}>
							{obj.fiscalized == 0 && <Button style={{marginRight: "10px"}} onClick={(e) => {this.handleFiscalizationComming(obj.id)}} variant="success">Фискализировать</Button>}
							{
								obj.fiscalized > 0 &&
									(obj.hasOwnProperty("checks") && obj.checks.length > 0 ?
										<ButtonGroup style={{marginRight: "10px"}}>
											<DropdownButton as={ButtonGroup} title="Чеки" id="bg-nested-dropdown">
												{obj.checks.slice(0).map((check, k) => 
													<Dropdown.Item eventKey={k} key={k} onClick={(e) => {this.handleShowCheck(e, i, k)}}>
														{check.status == 0 && <Spinner style={{marginRight: "10px"}} animation="border" size="sm" />}
														{(check.type.indexOf("comming", 0) != -1 ? "Приход №" : "Возврат №")+check.type[check.type.length-1]}
													</Dropdown.Item>
												)}
											</DropdownButton>
										</ButtonGroup>
									: <Alert key={i} variant="danger">Чеки не найдены!</Alert>
									)
							}
							{(obj.fiscalized == 1 || obj.fiscalized == 2) && obj.hasOwnProperty("checks") && <Button variant="danger" onClick={(e) => {this.handleRefundSelect(e, i)}}>Возврат</Button>}
						</td>
					</tr>
				) 
			: <tr></tr>
		);

        let leadComingAmount  = new Intl.NumberFormat({style: 'currency', currency: 'RUB'}).format(this.state.aggregate.leads_coming_amount);
        let leadRedundAmount  = new Intl.NumberFormat({style: 'currency', currency: 'RUB'}).format(this.state.aggregate.leads_refund_amount);
        let leadClearAmount  = new Intl.NumberFormat({style: 'currency', currency: 'RUB'}).format(this.state.aggregate.leads_clear_amount);

		return (
			<div>
                <Accordion >
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <span>Фильтрация</span>
                            <span style={{textAlign: "end", width: "inherit", fontSize: "14px"}}>Количество/сумма поступлений: <b>{this.state.aggregate.leads_coming_count}</b>/<b>{leadComingAmount}</b>₽
                            Количество/сумма возвратов: <b>{this.state.aggregate.leads_refund_count}</b>/<b>{leadRedundAmount}</b>₽
                            Количество/сумма чистого прихода: <b>{this.state.aggregate.leads_clear_count}</b>/<b>{leadClearAmount}</b>₽</span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Container>
                                <Row>
                                    <Col>
                                        <span>Дата ОТ:</span><DatePicker
                                            selected={this.state.filter.dateFrom}
                                            onChange={this.handleChangeFilterDateFrom}
                                            dateFormat="yyyy-MM-dd"
                                            style={{width: "128px"}}
                                        />
                                        <span>Дата ДО:</span><DatePicker
                                            selected={this.state.filter.dateTo}
                                            onChange={this.handleChangeFilterDateTo}
                                            dateFormat="yyyy-MM-dd"
                                            style={{width: "128px"}}
                                        />
                                    </Col>
                                    <Col>
                                    <span>По E-mail:</span><Form.Control style={{width: "256px"}} value={this.state.filter.email} size="sm" type="email" placeholder="E-mail" onChange={this.handleChangeFilterEmail}/>
                                    </Col>
                                    <Col>
                                    <span>По ФИО:</span><Form.Control style={{width: "256px"}} value={this.state.filter.FIO} size="sm" type="text" placeholder="ФИО" onChange={this.handleChangeFilterFIO}/>
                                    </Col>
                                </Row>
                            </Container>
                            <div style={{textAlign: "center"}}>
                                <Button style={{marginTop: "10px", width: "40%", marginRight: "10%"}} variant="primary" type="submit" size="sm" block onClick={(e) => {this.handleClickFilterEnable(true)}}>Применить</Button>
                                <Button style={{marginTop: "10px", width: "40%"}} variant="danger" type="submit" size="sm" block onClick={(e) => {this.handleClickFilterEnable(false)}}>Сбросить</Button>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
				<Table size="lg" striped bordered hover style={{marginBottom: "50px"}}>
					<thead>
						<tr>
							<th>№ / дата</th>
							<th>Сайт</th>
							<th>Покупатель / email</th>
							<th>Сумма</th>
							<th>Чеки</th>
						</tr>
					</thead>
					<tbody>
						{trs}
					</tbody>
				</Table>
				<Navbar bg="light" style={{
					position:"fixed",
					left:"0px",
					bottom:"0px",
					height: "50px",
					width:"100%",
					justifyContent: "center"
				}}>
					<PageNavi curr={this.state.pagination.curr} per={this.state.pagination.per} count={parseInt(Math.ceil(this.state.pagination.count/this.state.pagination.per))} handler={this.loadData} filterEnable={this.state.filter.enable} style={{alignItems: "flex-start;", padding: "0"}} />
				</Navbar>
			</div>
		)
	}

	//########################################################################

	loadData(iCurrPage=1, filter = false)
	{
		let iStart = (iCurrPage-1)*this.state.pagination.per;
		let iEnd = this.state.pagination.per;

        console.log(this.state.filter);

		let o = this;

        let sFilter = "";
        if(filter)
        {
            if(this.state.filter.dateFrom)
                sFilter += '&filter_date_from='+this.state.filter.dateFrom.toISOString();

            if(this.state.filter.dateTo)
                sFilter += '&filter_date_to='+this.state.filter.dateTo.toISOString();

            if(this.state.filter.email.length > 0)
                sFilter += '&filter_email='+this.state.filter.email;

            if(this.state.filter.FIO.length > 0)
                sFilter += '&filter_fio='+this.state.filter.FIO;
        }

		ajax({
			url: "/lead/get-list?select_related=project,customer,project.cashier&token="+localStorage.token+"&start="+iStart+"&end="+iEnd+sFilter,
			handler: function(json){
				o.setState({
					data: json.data,
					related: json.related,
                    aggregate: json.aggregate,
					pagination: {
						curr: iCurrPage, 
						per: o.state.pagination.per,
						count: json.count
					}
				});
			}
		});
	}

    //************************************************************************

    handleChangeFilterDateFrom(date)
    {
        this.setState({
            filter: {
                ...this.state.filter,
                dateFrom: date
            }
        });
    }

    handleChangeFilterDateTo(date)
    {
        this.setState({
            filter: {
                ...this.state.filter,
                dateTo: date
            }
        });
    }

    handleClickFilterEnable(filterEnable)
    {
        if(!filterEnable)
        {
            this.setState({
                filter: {
                    enable: filterEnable,
                    dateFrom: 0,
                    dateTo: 0,
                    email: "",
                    FIO: ""
                }
            });
        }
        else
            this.setState({
                filter: {
                    ...this.state.filter,
                    enable: filterEnable
                }
            });

        this.loadData(1, filterEnable);
    }

    handleChangeFilterEmail(event)
    {
        this.setState({
            filter: {
                ...this.state.filter,
                email: event.target.value
            }
        });
    }

    handleChangeFilterFIO(event)
    {
        this.setState({
            filter: {
                ...this.state.filter,
                FIO: event.target.value
            }
        });
    }

	//************************************************************************

	handleFiscalizationComming(idLead)
	{
		if(!window.confirm("\t\tо_О\n\nВы уверены что хотите ручную фискализацию?"))
			return;

		let o = this;
		ajax({
			url: "/lead/fiscalization/comming?lead="+idLead+"&token="+localStorage.token,
			type: "post",
			handler: (json) => {
				if(json.success)
				{
					o.props.notify.success("Чек прихода поставлен в очередь на фискализацию");
					o.loadData(o.state.pagination.curr, this.state.filter.enable);
				}
				else
					o.props.notify.error(json.error);
			}
		});
	}

	//########################################################################

	handleTrClick(e)
	{
		if(e.target.nodeName != "TD")
			return;

		let oLead = this.state.data[e.target.parentNode.id];
		this.props.fnModal({
			show: true, 
			header: "Товары заказа №"+this.state.data[e.target.parentNode.id].order, 
			content: <OrderProducts products={oLead} />,
			size: "lg",
			button: {}
		});
	}

	//************************************************************************

	handleShowCheck(e, iLead, iCheck)
	{
		console.log(iLead+' '+iCheck);

		let oLead = this.state.data[iLead];
		let oPropject = this.state.related.project[oLead.project];
		let oCheck = this.state.data[iLead].checks[iCheck];
		let oCashier = this.state.related.cashier[oPropject.cashier];

		if(oCheck.status == 0)
		{
			this.props.notify.warning("Чек / "+(oCheck.content.t1054 == 1 ? "приход" : "возврат")+", для заказа №"+oLead.order+" еще не готов!");
			return;
		}

		this.props.fnModal({
			show: true, 
			header: "Чек / "+(oCheck.content.t1054 == 1 ? "приход" : "возврат")+", заказ №"+oLead.order, 
			content: <Check 
				check={oCheck}
				tin={oCashier.tin}
			/>,
			size: "",
			button: {}
		});
	}

	//************************************************************************

	handleRefundSelect(e, iLead)
	{
		let oLead = this.state.data[iLead];
		let oCheckComming = null;
		for(let i=0; i<oLead.checks.length; ++i)
		{
			if(oLead.checks[i].type == "comming2")
			{
				oCheckComming = oLead.checks[i];
				break;
			}
            if(oLead.checks[i].type == "comming1")
				oCheckComming = oLead.checks[i];
		}

		let aContent4Refund = [];
		let aProducts = oCheckComming.content.t1059;
		for(let i=0; i<aProducts.length; ++i)
		{
			if(aProducts[i].t1023 > 1)
			{
				for(let k=0; k<aProducts[i].t1023; ++k)
					aContent4Refund.push({...aProducts[i], t1023: 1});
			}
			else
				aContent4Refund.push(aProducts[i]);
		}

		this.props.fnModal({
			show: true, 
			header: "Возврат заказа №"+oLead.order, 
			content: <Refund 
				products={aContent4Refund}
                lead={oLead.id}
                callback={this.handleRefundAction}
			/>,
			size: "lg",
			button: {}/*{
				text: "Оформить возврат",
				handler: () => {this.handleRefundAction(oLead.id);}
			}*/
		});

        //console.log(this.handleRefundAction);
	}

	//************************************************************************

	handleRefundAction(json)
	{
		this.props.notify.success("Чек возврата поставлен в очередь на фискализацию");
		this.loadData(this.state.pagination.curr, this.state.filter.enable);

		this.props.fnModal({show: false});
	}
}

export default Deals;
