
import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import { Redirect } from 'react-router-dom';

import { URL } from '../settings';

//##########################################################################

class TopPanel extends React.Component
{
	constructor()
	{
		super();
		this.state = {redirectURL: ""};
	}

	render()
	{
		if(this.state.redirectURL != "")
			return <Redirect to={this.state.redirectURL} />

		return (
			<Navbar sticky="top" bg="primary" variant="dark">
				<Navbar.Brand href="//digitalkassa.ru" style={{
                    backgroundImage: 'url(https://platform-v1.digitalincloud.ru/images/42х160.png)',
                    backgroundSize: 'contain',
                    height: '42px',
                    width: '160px',
                    display: 'block',
                    backgroundRepeat: 'no-repeat',
                }}>

                </Navbar.Brand>
				<Nav className="mr-auto">
					<Nav.Link href="/#/deals">Сделки</Nav.Link>
					<Nav.Link href="">Проекты</Nav.Link>
				</Nav>
				<Navbar.Collapse className="justify-content-end">
					<Navbar.Text>Вы вошли как: <u>{localStorage.login}</u></Navbar.Text>
					<Button variant="danger" size="sm" style={{marginLeft: "10px"}} onClick={()=>{localStorage.clear(); this.setState({redirectURL: URL.withoutAuth})}}>Выход</Button>
				</Navbar.Collapse>
			</Navbar>
		);
	}
}

export default TopPanel;
