import React, { useState } from 'react';
import {Button} from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';

//##########################################################################

function ModalShow(props) {
  const [show, setShow] = useState(props.show);

  const handleClose = () => { props.hide(); setShow(false); };

	let button = (
		props.hasOwnProperty("button") && props.button && props.button.hasOwnProperty("handler") && props.button.hasOwnProperty("text") ?
			<Modal.Footer>
				<Button variant="primary" onClick={(e) => {props.button.handler();}}>
					{props.button.text}
				</Button>
			</Modal.Footer>
		: ""
	);

  return (
		<Modal size={props.size} show={props.show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>{props.header}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{props.content}</Modal.Body>
			{button}
		</Modal>
  );
}

ModalShow.defaultProps = {
  size: ""
};

export default ModalShow;
