import Pagination from 'react-bootstrap/Pagination';

//##########################################################################

export default function PageNavi(props)
{
	/*const [curr, setCurr] = useState(props.curr);
	const [per, setPer] = useState(props.per);
	const [count, setCount] = useState(props.count);*/

	let arr = [];

	if(props.curr > 1)
	{
		arr.push(<Pagination.First key={-1} onClick={ () => {props.handler(1, props.filterEnable)} }/>)
		arr.push(<Pagination.Prev key={0} onClick={ () => {props.handler(props.curr-1, props.filterEnable)} }/>)
	}
	else
	{
		arr.push(<Pagination.First key={-1} disabled/>)
		arr.push(<Pagination.Prev key={0} disabled/>)
	}


	let iDist = 3;
	let iSide = (iDist - 1) / 2;
	let iStart = 0; let iEnd = 0;
	let iNode = 0;

	if(props.count - iDist >= props.curr-1)
	{
		iNode = 1;
		iStart = (props.curr - iSide > 0 ? props.curr - iSide : 1);
		iEnd = (iStart + iDist-1 <= props.count ? iStart + iDist-1 : props.count);
	}
	else
	{
		iNode = 2;
		iEnd = props.curr + iSide;
		iEnd = iEnd > props.count ? props.count : iEnd;
		iStart = iEnd - iDist +1;
		iStart = iStart > 0 ? iStart : 1;
	}

	//console.log({s: iStart, e: iEnd, curr: props.curr, count: props.count, n: iNode});

	for(let i=iStart; i<=iEnd; ++i)
	{
		if(props.curr != i)
			arr.push(<Pagination.Item key={i} onClick={ () => {props.handler(i, props.filterEnable)} }>{i}</Pagination.Item>);
		else
			arr.push(<Pagination.Item key={i} active>{i}</Pagination.Item>);
	}

	/*if(props.curr > 1)
		arr.push(<Pagination.Item onClick={ () => {props.handler(props.curr-1)} }>{props.curr-1}</Pagination.Item>);
	arr.push(<Pagination.Item active>{props.curr}</Pagination.Item>);
	if(props.count > props.curr)
		arr.push(<Pagination.Item onClick={ () => {props.handler(props.curr+1)} }>{props.curr+1}</Pagination.Item>);
	*/

	if(props.count > props.curr)
	{
		arr.push(<Pagination.Next key={-2} onClick={ () => {props.handler(props.curr+1, props.filterEnable)} }/>)
		arr.push(<Pagination.Last key={-3} onClick={ () => {props.handler(props.count, props.filterEnable)} }/>)
	}
	else
	{
		arr.push(<Pagination.Next key={-2} disabled/>)
		arr.push(<Pagination.Last key={-3} disabled/>)
	}

	return (
		<Pagination>
			{arr}
		</Pagination>
	)
}
