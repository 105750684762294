import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';

//##########################################################################

class OrderProducts extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {data: this.props.products};
	}

	render() 
	{
		const trProducts = (
			this.state.data.products.length ? 
				this.state.data.products.map((product, i) =>
					<tr key={i}>
						<td>{product.name}</td>
						<td>{product.hasOwnProperty("price_origin") && <strike>{product.price_origin}₽</strike>} {product.price}₽</td>
						<td>{product.quantity}</td>
						<td>{product.hasOwnProperty("amount_origin") && <strike>{product.amount_origin}₽</strike>} {product.amount}₽</td>
					</tr>
				) 
			: <tr></tr>
		);

		const delivery = (
			this.state.data.hasOwnProperty("delivery_text") && this.state.data.delivery_text.length > 0 ?
			<tr>
				<td>{this.state.data.delivery_text}</td>
				<td>{this.state.data.delivery_price}</td>
				<td>1</td>
				<td>{this.state.data.delivery_price.toFixed(2)}₽</td>
			</tr>
			: ""
		);

		const amount = <tr><td colSpan="4" style={{textAlign: "right"}}>
			{
				this.state.data.promocode.length > 0 &&	
				<span style={{marginRight: "10px"}}>
					<b>Промокод</b>: {this.state.data.promocode}, <b>Скидка</b>: {this.state.data.discount}₽ {(this.state.data.discountvalue.indexOf("%") != -1 ? "("+this.state.data.discountvalue+")" : "")}
				</span>
			}
			 <b>Итого</b>: {this.state.data.hasOwnProperty("discount") && this.state.data.discount > 0 && <strike>{this.state.data.amount+this.state.data.discount}₽</strike>} {this.state.data.amount}₽
			</td></tr>;

		return (
			<div>
				<Table striped bordered hover>
					<thead>
						<tr>
							<th>Название</th>
							<th>Цена за единицу</th>
							<th>Количество</th>
							<th>Сумма</th>
						</tr>
					</thead>
					<tbody>
						{trProducts}
						{delivery}
						{amount}
					</tbody>
				</Table>
			</div>
		)
	}
}

export default OrderProducts;
