import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter as Router, Route, Switch} from 'react-router-dom';

import NotFound from './pages/NotFound';
import Login from './pages/Login';
import Deals from './pages/Deals';

import Wrapping from './wrapping';
import reportWebVitals from './reportWebVitals';

import './index.css';

//##########################################################################

ReactDOM.render(
  <Router>
    <Switch>
      <Route exact path="/" component={Login} />
      <Route strict path="/deals" component={Wrapping(Deals)} />
      <Route component={NotFound} />
    </Switch>
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
