import React from 'react';
import { Redirect } from 'react-router-dom';
import ModalShow from './components/ModalShow';
import TopPanel from './components/TopPanel';

import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

import { URL } from './settings';

//##########################################################################

export default function Wrapping(CContent)
{
	return class CWrapped extends React.Component
	{
		constructor()
		{
			super();
			this.state = {
				modal: {
					show: false,
					header: "",
					content: "",
					button: {}
				}
			};

			this.handleHideModal = this.handleHideModal.bind(this);
			this.handleSetModal = this.handleSetModal.bind(this);
		}

		render()
		{
			if(
				!localStorage.hasOwnProperty("token") || 
				!localStorage.hasOwnProperty("expire") || 
				localStorage.expire < Date.now()/1000
			)
				return <Redirect to={URL.withoutAuth} />

			return (
				<>
					<ModalShow 
						size={this.state.modal.size} 
						show={this.state.modal.show} 
						hide={this.handleHideModal} 
						header={this.state.modal.header} 
						content={this.state.modal.content}
						button={this.state.modal.button}
					/>
					<TopPanel />
					<div className="content">
						<CContent fnModal={this.handleSetModal} notify={NotificationManager}/>
					</div>
					<NotificationContainer/>
				</>
			)
		}

		handleSetModal(obj)
		{
			this.setState({modal: obj});
		}

		handleHideModal()
		{
			this.setState({modal: {...this.state.modal, show: false}});
		}
	}
}
