
class pangaea
{

	static taxation_toText(i)
	{
		var oMap = {
			1: "ОРН",
			2: "УСН доход",
			4: "УСН доход - расход",
			16: "ЕСН",
			32: "ПСН",
		};

		return oMap[i];
	}

	static t1212_toText(i)
	{
		var oMap = {
			1: "Товар",
			2: "Подакцизный товар",
			3: "Работа",
			4: "Услуга",
			5: "Ставка азартной игры",
			6: "Выигрыш азартной игры",
			7: "Лотерейный билет",
			8: "Выигрыш лотереи",
			9: "Предоставление РИД",
			10: "Платеж",
			11: "Агентское вознаграждение",
			12: "Составной предмет расчета",
			13: "Иной предмет расчета",
			14: "Имущественное право",
			15: "Внереализационный доход",
			16: "Страховые взносы",
			17: "Торговый сбор",
			18: "Курортный сбор",
			19: "Залог",
		};

		return oMap[i];
	}

	//************************************************************************

	static t1214_toText(i)
	{
		var oMap = {
			1: "Предоплата 100%",
			2: "Частичная предоплата",
			3: "Аванс",
			4: "Полный расчет",
			5: "Частичный расчет и кредит",
			6: "Передача в кредит",
			7: "Оплата кредита",
		};

		return oMap[i];
	}

    //************************************************************************

    static t1199_toPercet(i)
    {
        var oMap = {
			1: 20,
			2: 10,
			3: 20,
			4: 10,
			5: 0,
			6: 0
		};

		return oMap[i];
    }
};

export default pangaea;