import React, { Component } from 'react';
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table';
import {Button} from 'react-bootstrap';
import ajax from '../ajax';

import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

//##########################################################################

class Refund extends Component
{
	constructor(props)
	{
		super(props);

		for(let i=0; i<this.props.products.length; ++i)
			this.props.products[i].__checked = false;

        let aProducts = [];
        for(let i=0; i<this.props.products.length; ++i)
        {
            const product = this.props.products[i];
            aProducts.push({
                checked: false, 
                name: product.t1030, 
                coming: parseFloat(product.t1079/100).toFixed(2),
                refund: parseFloat(product.t1079/100).toFixed(2),
                isValid: true
            });
        }

        this.state = {
            products: aProducts,
            lead: this.props.lead
        };

        this.handleRefundAction = this.handleRefundAction.bind(this);
	}

	render() 
	{
		//console.log(this.state);

		const products = (
			this.state.products.length ? 
				this.state.products.map((product, i) =>
					<tr key={i} /*onClick={(e)=>{let p = this.state.products; p[i].__checked=!p[i].__checked; this.setState({products: p})}}*/>
						<td style={{textAlign: "center"}}>
                            <Form.Check onChange={(e)=>{this.handleChangeCheck(e, i)}} className="refund_comming" name={i} type="checkbox" checked={product.__checked}/>
                        </td>
						<td>{product.name}</td>
						<td style={{textAlign: "right"}}>{product.coming}₽</td>
                        <td style={{textAlign: "right"}}>
                            <Form.Group controlId="formAmount">
								<Form.Control 
                                    type="number" /*value={product.t1079/100}*/ 
                                    onChange={e => this.handleChangeAmount(e, i, product.coming)} 
                                    defaultValue={product.refund} 
                                    required
                                    isValid={product.isValid}
                                    isInvalid={!product.isValid}
                                />
							</Form.Group>
                        </td>
					</tr>
				) 
			: <tr></tr>
		);

		return (
			<div>
                <Form onSubmit={this.handleRefundAction} className="border">
				<Table striped bordered hover>
					<thead>
						<tr>
							<th></th>
							<th>Наименование</th>
							<th style={{textAlign: "right"}}>Сумма прихода</th>
                            <th style={{textAlign: "right"}}>Сумма Возврата</th>
						</tr>
					</thead>
					<tbody>
						{products}
					</tbody>
				</Table>
                <Button variant="primary" type="submit" size="lg" block>Оформить возврат</Button>
                </Form>
			</div>
		)
	}

    handleChangeCheck(e, i)
	{
        let aProducts = this.state.products;
        aProducts[i].checked = !aProducts[i].checked;
        this.setState(aProducts);
	}

    handleChangeAmount(e, i, fAmount)
	{
		const value = e.target.valueAsNumber;
        let aProducts = this.state.products;
        aProducts[i].isValid = (value <= fAmount && value > 0);
        aProducts[i].refund = (aProducts[i].isValid ? value : fAmount);
        this.setState({products: aProducts});
	}

    handleRefundAction(e)
    {
        e.preventDefault();
        let positions = {};

        for(let i=0; i<this.state.products.length; ++i)
        {
            let product = this.state.products[i];
            if(!product.isValid)
            {
                NotificationManager.error("Есть ошибки в форме возврата");
                return;
            }

            if(product.checked)
                positions[i] = parseInt(product.refund*100);
        }

        console.log(positions);

        if(Object.keys(positions).length == 0)
		{
			alert("Выберите товары для возврата");
			return;
		}

		ajax({
			url: "/lead/fiscalization/refund?lead="+this.state.lead+"&token="+localStorage.token,
			type: "post",
			data: {positions: positions},
			handler: (json) => {
				this.props.callback(json);
			}
		});
    }
}

export default Refund;
