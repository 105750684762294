import React from 'react';
import {Button} from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

//##########################################################################

class NotFound extends React.Component{
  render(){
    return (
      <div className="container-fluid d-flex justify-content-center">
        <div className="row align-items-center min-vh-100">
          <div className="col-12">
            <h1 className="text-center">404 - Ресурс не найден</h1>
            <Button variant="outline-primary" type="submit" size="lg" block href="/">Фискализация заказов с Tilda</Button>
            <Button variant="outline-secondary" type="submit" size="lg" block href="//digitalkassa.ru">облачные кассы для интернет-магазинов</Button>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFound;
